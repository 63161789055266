<template>
    <div
        @dragstart.prevent

        :class="[
            'flex',
      'el-input-number',
      inputNumberSize ? 'el-input-number--' + inputNumberSize : '',
      { 'is-disabled': inputNumberDisabled },

    ]">
        <span v-if="!isPercentage && !hideSymbols" class="currency">{{ currencySymbol }}</span>
        <el-input
            ref="input"
            :value="displayValue"
            :placeholder="placeholder"
            :disabled="inputNumberDisabled"
            :size="inputNumberSize"
            :step="1"
            :name="name"
            :label="label"
            @keydown.up.native.prevent="increase"
            @keydown.down.native.prevent="decrease"
            @blur="handleBlur"
            @focus="handleFocus"
            @input="handleInput"
            @change="handleInputChange"
            :autofocus="autofocus"

        >
            <template slot="prefix">
                {{prefix}}
            </template>

        </el-input>
        <span v-if="isPercentage && !hideSymbols" class="percentage-symbol">%</span>
    </div>
</template>
<script>

import RepeatClick from 'element-ui/src/directives/repeat-click';

export default {
    name: 'ElInputNumber',
    inject: {
        elForm: {
            default: ''
        },
        elFormItem: {
            default: ''
        }
    },
    directives: {
        repeatClick: RepeatClick
    },
    props: {

        prefix: {
            type: String,
            default: '',
        },
        hideSymbols: {
            type: Boolean,
            default: false,
        },
        isPercentage: {
            type: Boolean,
            default: false,
        },
        autofocus:{
            type: Boolean,
            default: false
        },
        step: {
            type: Number,
            default: 1
        },
        stepStrictly: {
            type: Boolean,
            default: false
        },
        max: {
            type: Number,

            // Theoretical max from stripe
            default: 999999.99
        },
        min: {
            type: Number,
            default: 0
        },
        value: {
            required: true,
        },
        disabled: Boolean,
        size: String,
        controls: {
            type: Boolean,
            default: true
        },
        controlsPosition: {
            type: String,
            default: ''
        },
        name: String,
        label: String,
        placeholder: String,
        precision: {
            type: Number,
            default: 2,
            validator(val) {
                return val >= 0 && val === parseInt(val, 10);
            }
        }
    },
    data() {
        return {
            currentValue: 0,
            userInput: null
        };
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {


                // This is a security check to ensure that if the value prop is getting a string, this is properly formatted
                if(typeof value === 'string'){
                    value = value.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2");
                }

                let newVal = (value === undefined) ? value : Number((value));

                if (newVal !== undefined) {
                    if (isNaN(newVal)) {
                        return;
                    }

                    if (this.stepStrictly) {
                        const stepPrecision = this.getPrecision(this.step);
                        const precisionFactor = Math.pow(10, stepPrecision);
                        newVal = Math.round(newVal / this.step) * precisionFactor * this.step / precisionFactor;
                    }

                    if (this.precision !== undefined) {
                        newVal = this.toPrecision(newVal, this.precision);
                    }
                }
                if (newVal >= this.max) newVal = this.max;
                if (newVal <= this.min) newVal = this.min;

                this.currentValue =  typeof newVal === 'number' ? newVal.toFixed(2) : newVal;
                this.userInput = null;

                this.$emit('input', this.currentValue);
            }
        }
    },
    computed: {
        minDisabled() {
            return this._decrease(this.value, this.step) < this.min;
        },
        maxDisabled() {
            return this._increase(this.value, this.step) > this.max;
        },
        numPrecision() {
            const { value, step, getPrecision, precision } = this;
            const stepPrecision = getPrecision(step);
            if (precision !== undefined) {
                if (stepPrecision > precision) {
                    console.warn('[Element Warn][InputNumber]precision should not be less than the decimal places of step');
                }
                return precision;
            } else {
                return Math.max(getPrecision(value), stepPrecision);
            }
        },
        controlsAtRight() {
            return this.controls && this.controlsPosition === 'right';
        },
        _elFormItemSize() {
            return (this.elFormItem || {}).elFormItemSize;
        },
        inputNumberSize() {
            return this.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
        },
        inputNumberDisabled() {
            return this.disabled || !!(this.elForm || {}).disabled;
        },
        displayValue() {

            if (this.userInput !== null) {
                return this.userInput;
            }

            let currentValue = this.currentValue;

            if (typeof currentValue === 'number') {
                if (this.stepStrictly) {
                    const stepPrecision = this.getPrecision(this.step);
                    const precisionFactor = Math.pow(10, stepPrecision);
                    currentValue = Math.round(currentValue / this.step) * precisionFactor * this.step / precisionFactor;
                }

                if (this.precision !== undefined) {
                    currentValue = currentValue.toFixed(this.precision);
                }
            }else if (currentValue === undefined){
                currentValue = Number(0).toFixed(this.precision);
            }


            return currentValue?.replace(/[^.\d]/g, '')?.replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2")

        }
    },
    methods: {
        toPrecision(num, precision) {
            if (precision === undefined) precision = this.numPrecision;
            return parseFloat(Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision));
        },
        getPrecision(value) {
            if (value === undefined) return 0;
            const valueString = value.toString();
            const dotPosition = valueString.indexOf('.');
            let precision = 0;
            if (dotPosition !== -1) {
                precision = valueString.length - dotPosition - 1;
            }
            return precision;
        },
        _resizeInput(value = undefined){

            if(this.size)
                return;

            const inputNumber = this.$refs.input.$el.querySelector('input');
            if (inputNumber) {

                const span = document.createElement('span');
                span.style.visibility = 'hidden';
                span.style.whiteSpace = 'pre';
                span.style.fontFamily = getComputedStyle(inputNumber).fontFamily;
                span.style.fontSize = getComputedStyle(inputNumber).fontSize;
                span.style.fontWeight = getComputedStyle(inputNumber).fontWeight;

                span.textContent = value || this.displayValue ;

                document.body.appendChild(span);


                let paddingleft =  parseFloat(window.getComputedStyle(inputNumber).paddingLeft);
                let paddingRight = parseFloat(window.getComputedStyle(inputNumber).paddingRight);

                let totalPadding = paddingRight + paddingleft ;

                inputNumber.style.width = `${span.getBoundingClientRect().width + totalPadding }px`;


                document.body.removeChild(span);
            }

        },
        _increase(val, step) {
            if (typeof val !== 'number' && val !== undefined) return this.currentValue;

            const precisionFactor = Math.pow(10, this.numPrecision);
            // Solve the accuracy problem of JS decimal calculation by converting the value to integer.
            return this.toPrecision((precisionFactor * val + precisionFactor * step) / precisionFactor);
        },
        _decrease(val, step) {
            if (typeof val !== 'number' && val !== undefined) return this.currentValue;

            const precisionFactor = Math.pow(10, this.numPrecision);

            return this.toPrecision((precisionFactor * val - precisionFactor * step) / precisionFactor);
        },
        increase() {
            if (this.inputNumberDisabled || this.maxDisabled) return;
            const value = this.value || 0;
            const newVal = this._increase(value, this.step);
            this.setCurrentValue(newVal);
        },
        decrease() {
            if (this.inputNumberDisabled || this.minDisabled) return;
            const value = this.value || 0;
            const newVal = this._decrease(value, this.step);
            this.setCurrentValue(newVal);
        },
        handleBlur(event) {
            this._resizeInput()
            this.$emit('blur', event);
        },
        handleFocus(event) {
            this._resizeInput()
            this.$emit('focus', event);
        },
        setCurrentValue(newVal) {
            const oldVal = this.currentValue;
            if (typeof newVal === 'number' && this.precision !== undefined) {
                newVal = this.toPrecision(newVal, this.precision);
            }
            if (newVal >= this.max) newVal = this.max;
            if (newVal <= this.min) newVal = this.min;
            if (oldVal === newVal) return;
            this.userInput = null;
            this.$emit('input', newVal);
            this.$emit('change', newVal, oldVal);
            this.currentValue = newVal;

        },
        handleInput(value) {
            // ensure inputs is within max number with max 2 decimals
            if(value.match(/^\d{0,6}(\.\d{0,2})?$/g)){
                this.userInput = value;
                this._resizeInput(value)
            }

        },
        handleInputChange(value) {
            const newVal = value === '' ? undefined : Number(value);
            if (!isNaN(newVal) || value === '') {
                this.setCurrentValue(newVal);
            }
            this.userInput = null;

        },
        select() {
            this.$refs.input.select();
        }
    },
    mounted() {
        let innerInput = this.$refs.input.$refs.input;
        innerInput.setAttribute('role', 'spinbutton');
        innerInput.setAttribute('aria-valuemax', this.max);
        innerInput.setAttribute('aria-valuemin', this.min);
        innerInput.setAttribute('aria-valuenow', this.currentValue);
        innerInput.setAttribute('aria-disabled', this.inputNumberDisabled);
        this._resizeInput();
    },
    updated() {
        if (!this.$refs || !this.$refs.input) return;
        const innerInput = this.$refs.input.$refs.input;
        innerInput.setAttribute('aria-valuenow', this.currentValue);
        this._resizeInput()
    }
};
</script>
