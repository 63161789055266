
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import router from './router.js'
import LaravelPermissionToVueJS from 'laravel-permission-to-vuejs/index.js'
import GmapVue from 'gmap-vue'


import * as Sentry from "@sentry/vue";
Sentry.init({
    Vue,
    dsn: import.meta.env.VITE_SENTRY_KEY,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/convertlabs\.io/],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


import mixin from './mixins.js'
Vue.mixin(mixin);


import 'flex.box/src/flexbox.css'


import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
Vue.use(LottieVuePlayer);


import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueSession from "vue-session"
import autowidth from "../directives/autowidth.js";

import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/display.css';

import Vuebar from 'vuebar'
import store from './store/store.js'

/* MDI Icons

 In order to use different/new icons need to be imported BELLOW mdi{IconName}
 this is done to improve tree shaking and avoid unused icons to be bundled

 */

import VueMdijs from 'vue-mdijs'
import {
    mdiEyeOutline,
    mdiTriangleSmallUp,
    mdiTriangleSmallDown,
    mdiFilterVariant,
    mdiHistory,
    mdiUpdate,
    mdiViewDashboard,
    mdiBell,
    mdiBellOutline,
    mdiMenu,
    mdiAccount,
    mdiPhone,
    mdiEmail,
    mdiMapMarker,
    mdiMagnify,
    mdiPencilOutline,
    mdiDotsVertical,
    mdiContentDuplicate,
    mdiCashCheck,
    mdiEmailSendOutline,
    mdiCalendarMonth,
    mdiCircle,
    mdiLockReset,
    mdiTrashCanOutline,
    mdiCurrencyUsd,
    mdiAlertCircleOutline,
    mdiCheck,
    mdiClose,
    mdiInstagram,
    mdiTwitter,
    mdiPinterest,
    mdiLinkedin,
    mdiYoutube,
    mdiFacebook,
    mdiFileDocument,
    mdiFileDocumentOutline,
    mdiCardAccountDetails,
    mdiCheckboxMultipleMarked,
    mdiCalendarAccount,
    mdiFinance,
    mdiWeb,
    mdiBriefcasePlusOutline,
    mdiLogout,
    mdiAccountOutline,
    mdiFormatListCheckbox,
    mdiAccountGroup,
    mdiAt,
    mdiHelpCircleOutline,
    mdiFormatListBulleted,
    mdiContentCopy,
    mdiAccountCheckOutline,
    mdiAccountCancelOutline,
    mdiArrowUp,
    mdiArrowDown,
    mdiMinus,
    mdiChartBox,
    mdiTrello,
    mdiViewDashboardVariant,
    mdiFormatListText,
    mdiOpenInNew,
    mdiAccountPlus,
    mdiNotePlus,
    mdiDownload,
    mdiAccountMultiplePlus,
    mdiCreditCardPlus,
    mdiFlash,
    mdiCircleOutline,
    mdiBlockHelper,
    mdiStar,
    mdiBookArrowRight,
    mdiCreditCardOutline,
    mdiCog,
    mdiLightbulbOnOutline,
    mdiRotate360,
    mdiBedQueenOutline,
    mdiHomeOutline,
    mdiShower,
    mdiLink,
    mdiWalletGiftcard,
    mdiTimerEditOutline,
    mdiApplicationOutline,
    mdiLaptop,
    mdiWidgetsOutline,
    mdiRedoVariant,
    mdiCancel,
    mdiWebPlus,
    mdiRestore,
    mdiOpenInApp,
    mdiExpandAllOutline,
    mdiCollapseAllOutline,
    mdiDragVariant,
    mdiInformationOutline,
    mdiFileFind,
    mdiBookPlusMultipleOutline,
    mdiTimerAlertOutline,

} from '@mdi/js';
//
VueMdijs.add({
    mdiEyeOutline,
    mdiTriangleSmallUp,
    mdiTriangleSmallDown,
    mdiFilterVariant,
    mdiHistory,
    mdiUpdate,
    mdiViewDashboard,
    mdiBell,
    mdiBellOutline,
    mdiMenu,
    mdiAccount,
    mdiPhone,
    mdiEmail,
    mdiMapMarker,
    mdiMagnify,
    mdiPencilOutline,
    mdiDotsVertical,
    mdiContentDuplicate,
    mdiCashCheck,
    mdiEmailSendOutline,
    mdiCalendarMonth,
    mdiCircle,
    mdiLockReset,
    mdiTrashCanOutline,
    mdiCurrencyUsd,
    mdiAlertCircleOutline,
    mdiCheck,
    mdiClose,
    mdiInstagram,
    mdiTwitter,
    mdiPinterest,
    mdiLinkedin,
    mdiYoutube,
    mdiFacebook,
    mdiFileDocument,
    mdiFileDocumentOutline,
    mdiCardAccountDetails,
    mdiCheckboxMultipleMarked,
    mdiCalendarAccount,
    mdiFinance,
    mdiWeb,
    mdiBriefcasePlusOutline,
    mdiLogout,
    mdiAccountOutline,
    mdiFormatListCheckbox,
    mdiAccountGroup,
    mdiAt,
    mdiHelpCircleOutline,
    mdiFormatListBulleted,
    mdiContentCopy,
    mdiAccountCheckOutline,
    mdiAccountCancelOutline,
    mdiArrowUp,
    mdiArrowDown,
    mdiMinus,
    mdiChartBox,
    mdiTrello,
    mdiViewDashboardVariant,
    mdiFormatListText,
    mdiOpenInNew,
    mdiAccountPlus,
    mdiNotePlus,
    mdiDownload,
    mdiAccountMultiplePlus,
    mdiCreditCardPlus,
    mdiFlash,
    mdiCircleOutline,
    mdiBlockHelper,
    mdiStar,
    mdiBookArrowRight,
    mdiCreditCardOutline,
    mdiCog,
    mdiLightbulbOnOutline,
    mdiRotate360,
    mdiBedQueenOutline,
    mdiHomeOutline,
    mdiShower,
    mdiLink,
    mdiWalletGiftcard,
    mdiTimerEditOutline,
    mdiApplicationOutline,
    mdiLaptop,
    mdiWidgetsOutline,
    mdiRedoVariant,
    mdiCancel,
    mdiWebPlus,
    mdiRestore,
    mdiOpenInApp,
    mdiExpandAllOutline,
    mdiCollapseAllOutline,
    mdiDragVariant,
    mdiInformationOutline,
    mdiFileFind,
    mdiBookPlusMultipleOutline,
    mdiTimerAlertOutline,
})
Vue.use(VueMdijs)


/* Local Components */
const  Dashboard = () => import(/* webpackChunkName: "dash-v2" */ './index.vue')

import icon from '@components/c-icon/index.vue'

Vue.component('c-icon', icon)


import inputMoney from '@components/c-input-money/inputMoney.vue'
Vue.component('c-input-money', inputMoney)

Vue.use(VueAxios, axios)
Vue.use(VueRouter)
Vue.use(ElementUI, { locale })
Vue.use(VueMoment, { moment})
Vue.use(VueSession)
Vue.use(Vuebar)
Vue.use(LaravelPermissionToVueJS)


Vue.use(GmapVue, {
    load: {
      // [REQUIRED] This is the unique required value by Google Maps API
      key:  import.meta.env.VITE_GMAPS_KEY,
      // [OPTIONAL] This is required if you use the Autocomplete plugin
      // OR: libraries: 'places,drawing'
      // OR: libraries: 'places,drawing,visualization'
      // (as you require)
      libraries: 'places,drawing',

      // This option was added on v3.0.0 but will be removed in the next major release.
      // If you already have an script tag that loads Google Maps API and you want to use it set you callback
      // here and our callback `GoogleMapsCallback` will execute your custom callback at the end; it must attached
      // to the `window` object, is the only requirement.
    //   customCallback: 'MyCustomCallback',
    },

    // [OPTIONAL] If you intend to programmatically custom event listener code
    // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    // you might need to turn this on.
    autoBindAllEvents: false,

    // [OPTIONAL] If you want to manually install components, e.g.
    // import {GmapMarker} from 'gmap-vue/src/components/marker'
    // Vue.component('GmapMarker', GmapMarker)
    // then set installComponents to 'false'.
    // If you want to automatically install all the components this property must be set to 'true':
    installComponents: true,

    // Load the Google Maps API dynamically, if you set this to `true` the plugin doesn't load the Google Maps API
    dynamicLoad: false,
  })




Vue.component('vue-scroll', VuePerfectScrollbar)

// Directive to autoresize el-select elements
Vue.directive('autowidth', autowidth)

// @TODO REPLACE ALL TO FIXED FOR TOMONEY
Vue.filter('toFixed', function (value) {

    var numberValue = Number(value);

    if (!numberValue) numberValue = 0
    numberValue = numberValue.toFixed(2)
    return numberValue
})

// @TODO CHANGE FOR ToMoney FILTER CREATED BY DANIEL
Vue.filter('formatMoney', function (value) {

    var numberValue = Number(value);

    if (!numberValue) numberValue = 0
    numberValue = parseFloat(numberValue).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    return numberValue
})

// Format Money leaderboard (K)
Vue.filter('formatMoneyInK', function (value) {

    var numberValue = Number(value);

    if(isNaN(numberValue))
        return 0;

    if (numberValue >= 10 ** 3 && numberValue < 10 ** 6) {
        numberValue = (numberValue / 1000).toFixed(0) + 'K'
    } else if (numberValue >= 10 ** 6) {
        numberValue = (numberValue / 1000000).toFixed(0) + 'M'
    } else {
        numberValue = numberValue.toFixed(0);
    }

    return numberValue
})



Vue.config.productionTip = false

// @TODO THIS NEEDS TO BE DONE ANOTHER WAY
router.beforeEach((to, from, next) => {
    axios.defaults.baseURL = ((to.matched[0]?.path === '/domains') || (to.matched[0]?.path === '/websites' )) ? `/api/multi-tenant` : '/api'
    next()
})


import { Notification } from 'element-ui';

/* Force redirection to login when backend returns http 401 */
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;

// Add a request interceptor
axios.interceptors.request.use(function (config) {

    // Getting client timezone
    config.headers['X-Timezone'] = moment.tz.guess();

    if(config.headers['Content-Type'] !== 'multipart/form-data'){

        config.params = {location_id: Number(store.state.location_id) ,...config?.params, };
        config.data = {location_id: Number(store.state.location_id) ,...config?.data, };

    }else{
        config.data.append('location_id', Number(store.state.location_id))
    }


    return config;

}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

axios.interceptors.response.use( function (response) {

        /* responsible of enabling the canceled subscription message */
        Vue.prototype.$subscription.active =  !response.data.canceledAccount
        return response

}, function (error) {

    // If the request returned an HTTP code
    if(error.response){

        if (error.response.status === 401) {
            // router.push('/login').catch(() => {})

            return Promise.reject(error).finally(() => {
                document.location = "/login";
            })

        }
        else if (error.response.status === 403) {

            return Promise.reject({
                response: {
                    data: {
                        'message': error.response.data.message || 'Your account does not have enough permissions'}
                }
            })
        }
    }

    return Promise.reject(error)

})


window.addEventListener('vite:preloadError', (event) => {
    window.location.reload() // for example, refresh the page
})


document.addEventListener('DOMContentLoaded', () => {

const instanceElement = document.getElementById('app')

Vue.prototype.$clientId  = instanceElement.dataset.clientId
Vue.prototype.$stripeKey = instanceElement.dataset.stripeKey
Vue.prototype.$locationEnabled = (instanceElement.dataset.locationEnabled == 0)?false:true
Vue.prototype.$serviceCurrency = JSON.parse(instanceElement.dataset.serviceCurrency)
Vue.prototype.$guardModel = "member"
Vue.prototype.$subscription =  Vue.observable({ id:0, active: true, type:'normal' })
Vue.prototype.$isOnboardingCompleted = Vue.observable(!!window.is_onboarding_completed);

new Vue({
    el: '#app',
    router,
    render: h => h( Dashboard ,{})
})

// if (module.hot) {
//     module.hot.accept()
// }

})

